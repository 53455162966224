import * as Yup from "yup"

let REQUIRED_MESSAGE = "This field is required"
let VALID_EMAIL = "Please input a valid email"
let VALID_MOBILE_NUMBER =
  "Please input a valid mobile number in this format: 9991234567"
let VALID_LANDLINE_NUMBER =
  "Please input a valid landline number in this format: 212345678"
let VALID_PRC_LICENSE_NUMBER =
  "Please input a valid PRC license number in this format: 1234567"

export const getDefaultValidation = ({ field, t }) => {
  if (t) {
    REQUIRED_MESSAGE = t(REQUIRED_MESSAGE)
    VALID_EMAIL = t(VALID_EMAIL)
    VALID_MOBILE_NUMBER = t(VALID_MOBILE_NUMBER)
    VALID_LANDLINE_NUMBER = t(VALID_LANDLINE_NUMBER)
    VALID_PRC_LICENSE_NUMBER = t(VALID_PRC_LICENSE_NUMBER)
  }
  let fieldValidation
  switch (field.type) {
    case "checkbox":
      fieldValidation = Yup.array()
      break

    case "schedule":
      fieldValidation = Yup.object()

      for (let objectField of field.fieldNames) {
        fieldValidation = fieldValidation.concat(
          fieldValidation.shape({
            [objectField]: Yup.string().required(REQUIRED_MESSAGE),
          })
        )
      }

      break

    case "select":
      fieldValidation = Yup.object().shape({
        value: Yup.string().when("label", {
          is: () => !!field.required,
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
      })
      break

    case "hospital":
    case "height":
    case "weight":
      fieldValidation = Yup.object().shape({
        value: Yup.string().when("label", {
          is: () => !!field.required,
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
      })
      // fieldValidation = fieldValidation.concat(Yup.object().shape({
      //   otherHospital: Yup.string().when("value", {
      //     is: (name) => name === "Other",
      //     then: Yup.string().required(REQUIRED_MESSAGE)
      //   })
      // }))
      break

    case "multiselect":
      fieldValidation = Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().when("label", {
              is: () => !!field.required,
              then: Yup.string().required(REQUIRED_MESSAGE),
              otherwise: Yup.string(),
            }),
          })
        )
        .min(field?.min || 1, REQUIRED_MESSAGE)
      break

    case "date":
      fieldValidation = Yup.object().shape({
        month: Yup.object().shape({
          value: Yup.string().when("label", {
            is: () => !!field.required && !field.isFollowUpQuestion,
            then: Yup.string().required(REQUIRED_MESSAGE),
            otherwise: Yup.string(),
          }),
        }),
        date: Yup.object().shape({
          value: Yup.string().when("label", {
            is: () => !!field.required && !field.isFollowUpQuestion,
            then: Yup.string().required(REQUIRED_MESSAGE),
            otherwise: Yup.string(),
          }),
        }),
        year: Yup.object().shape({
          value: Yup.string().when("label", {
            is: () => !!field.required && !field.isFollowUpQuestion,
            then: Yup.string().required(REQUIRED_MESSAGE),
            otherwise: Yup.string(),
          }),
        }),
        // year: Yup.number().when("month", {
        //   is: () => !!field.required,
        //   then: Yup.number().when("month", {
        //     is: () => field?.validation?.includes("Birthday"),
        //     then: Yup.number()
        //       .min(1900, "Please input a valid year")
        //       .max(yearNow - parseInt(field.min), "Please input a valid year")
        //       .required(REQUIRED_MESSAGE),
        //     otherwise: Yup.number().when("month", {
        //       is: () => !!field?.isFutureDatesOnly,
        //       then: Yup.number()
        //         .min(yearNow, "Please input a valid year")
        //         .required(REQUIRED_MESSAGE),
        //       otherwise: Yup.number()
        //         .min(1900, "Please input a valid year")
        //         .max(yearNow)
        //         .required(REQUIRED_MESSAGE),
        //     }),
        //   }),
        //   otherwise: Yup.number(),
        // }),
      })
      break

    case "address":
      let shouldInputSite = field.fieldNames.includes(`${field.name}.siteName`)
      let hasStreetAddress = field.fieldNames.includes(
        `${field.name}.streetAddress`
      )
      let hasBarangay = field.fieldNames.includes(`${field.name}.barangay`)

      fieldValidation = Yup.object().shape({
        city: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        province: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        barangay: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")) &&
            hasBarangay,
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        siteName: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required && shouldInputSite && addressType === "Office",
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        streetAddress: Yup.string().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")) &&
            hasStreetAddress,
          then: Yup.string().trim().required(REQUIRED_MESSAGE),
          otherwise: Yup.string().trim(),
        }),
        // addressType: Yup.string().when("value", {
        //   is: () => !!field.required,
        //   then: Yup.string().required(REQUIRED_MESSAGE),
        //   otherwise: Yup.string(),
        // }),
        // notes: Yup.string(),
      })
      break

    // case "address":
    //   fieldValidation = Yup.object().shape({
    //     addressType: Yup.string(),
    //     streetAddress: Yup.string(),
    //     province: Yup.object().shape({
    //       label: Yup.string(),
    //       value: Yup.string(),
    //     }),
    //     city: Yup.object().shape({
    //       label: Yup.string(),
    //       value: Yup.string(),
    //     }),
    //   })
    //   break

    case "number":
    case "text":
    case "radio":
    case "textarea":
    default:
      fieldValidation = Yup.string().trim()

      if (field?.validation?.includes("Email"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.email(VALID_EMAIL)
        )

      if (field?.validation?.includes("Mobile Number"))
        fieldValidation = fieldValidation.concat(
          fieldValidation
            .min(10, VALID_MOBILE_NUMBER)
            .max(10, VALID_MOBILE_NUMBER)
            .matches(/^9[0-9]{9}$/, VALID_MOBILE_NUMBER)
        )

      if (field?.validation?.includes("Landline"))
        fieldValidation = fieldValidation.concat(
          fieldValidation
            .min(9, VALID_LANDLINE_NUMBER)
            .max(10, VALID_LANDLINE_NUMBER)
            .matches(/^2[0-9]{8,9}$/, VALID_LANDLINE_NUMBER)
        )

      if (field?.validation?.includes("PRC"))
        fieldValidation = fieldValidation.concat(
          fieldValidation
            .min(7, VALID_PRC_LICENSE_NUMBER)
            .max(7, VALID_PRC_LICENSE_NUMBER)
            .matches(/^[0-9]{7}$/, VALID_PRC_LICENSE_NUMBER)
        )

      // validates otherHospital field made by FormHospitalSelect component
      // NOTE: otherHospital exists in airtable with "Other Hospital" for its validation
      if (field?.validation?.includes("Other Hospital"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.when("hospitalName", {
            is: (name) => name?.value === "Other",
            then: Yup.string().required(REQUIRED_MESSAGE),
          })
        )
      if (field?.validation?.includes("Other Specialization"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.when("specialization", {
            is: (name) => name === "Other",
            then: Yup.string().required(REQUIRED_MESSAGE),
          })
        )

      break
  }

  return fieldValidation
}

export const getCustomValidation = ({ field, fieldValidation }) => {
  if (!!field.required) {
    if (!!field.referenceAnswer) {
      let referenceQuestion = field.referenceQuestionName[0]

      fieldValidation = fieldValidation.concat(
        fieldValidation.when(referenceQuestion, {
          is: (referenceAnswer) =>
            referenceAnswer === field?.referenceAnswer ||
            referenceAnswer?.includes(field?.referenceAnswer),
          then: fieldValidation.required(REQUIRED_MESSAGE),
        })
      )
    } else {
      fieldValidation = fieldValidation.concat(
        fieldValidation.required(REQUIRED_MESSAGE)
      )
    }

    if (!!field.minLength) {
      fieldValidation = fieldValidation.concat(
        fieldValidation.min(field.minLength, REQUIRED_MESSAGE)
      )
    }
  }

  return fieldValidation
}
