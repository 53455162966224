import * as Yup from "yup"
import {
  getCustomValidation,
  getDefaultValidation,
} from "../../../services/validations"

export const formSchema = ({ fields, t }) => {
  let validationSchema = Yup.object()

  for (let field of fields) {
    let fieldValidation = getDefaultValidation({ field, t })
    fieldValidation = getCustomValidation({ field, fieldValidation })
    validationSchema = validationSchema.concat(
      Yup.object().shape({
        [field.name]: fieldValidation,
      })
    )
  }

  return validationSchema
}
