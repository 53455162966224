import React, { useState, useContext, useEffect, Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "../Layout"
import Container from "../Layout/Container"
import Section from "../Elements/Section"
import Message from "../Elements/Message"
import ActionButtons from "../Elements/ActionButtons"
import DeliveryAddress from "./Checkout/DeliveryAddress"
import { generateFormField } from "../Elements/Form/services/form"

import { firebaseApi } from "services/firebaseApi"
import { generateJWT } from "services/jwt"
import { isBrowser } from "../../services/general"

import { formSchema } from "./utils/formSchema"
import { EpharmacyContext } from "./EpharmacyContext/EpharmacyContext"
import { generateInitialValues } from "./utils/generateIntialValues"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import FormRequiredFields from "../Elements/Form/FormRequiredFields"
import { assignInitialValues } from "./services/assignInitialValues"
import { getSignedInUser } from "../Auth/services/user"
import { buildSessionDocument } from "../Elements/utils/buildSessionDocument"
import trimObjectWhitespaces from "./utils/trimObjectWhitespaces"
import {
  GATSBY_ORDER_AS_GUEST_KEY,
  GATSBY_ORDER_AS_GUEST_VALUE,
  GATSBY_IS_AUTH_ACTIVATED,
} from "gatsby-env-variables"

//import { getSignedInUser } from "../Auth/services/user"

let { parseFormField } = require("../../services/airtable")

const DoctorEnrollmentForm = ({ pageContext, location }) => {
  const [loading, setLoading] = useState(false)
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const { module } = pageContext
  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )
  useEffect(() => {
    epharmacyDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [epharmacyDispatch])

  let userData = getSignedInUser()?.userData
  let userAddresses = getSignedInUser()?.addresses

  let addressList = userAddresses?.addresses || []
  addressList = addressList?.filter(
    (address) => address?.province === "Metro Manila"
  )

  const addressFieldNames = {
    addressType: `address.addressType`,
    streetAddress: `deliveryAddress.streetAddress`,
    city: `deliveryAddress.city`,
    province: `deliveryAddress.province`,
    barangay: `deliveryAddress.barangay`,
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    values = trimObjectWhitespaces(values)
    await epharmacyDispatch({
      type: "SAVE_EPHARMACY",
      payload: values,
    })

    await epharmacyDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: values,
    })

    if (
      isBrowser() &&
      (sessionStorage.getItem(GATSBY_ORDER_AS_GUEST_KEY) ===
        GATSBY_ORDER_AS_GUEST_VALUE ||
        !GATSBY_IS_AUTH_ACTIVATED)
    ) {
      const JWT_OBJECT = await generateJWT()
      const ACCESS_TOKEN = JWT_OBJECT?.data?.access_token

      let request = await firebaseApi({
        accessToken: ACCESS_TOKEN,
      }).post(`sessions`, {
        fields: buildSessionDocument({ values, location }),
      })
    }

    setLoading(false)
    navigate(pageContext?.nextPath)
  }

  let initialValues = generateInitialValues({ fields: formFields })

  return (
    <Layout
      {...module}
      display={{
        footer: false,
        helpCenterBanner: true,
        bottomMargin: true,
        progressBar: true,
      }}
      pageContext={pageContext}
      isPrivate={pageContext?.module?.isPrivate}
      currentStep={3}
      totalSteps={3}
    >
      <Container isCentered>
        <Formik
          initialValues={assignInitialValues({
            initialValues,
            states: [userData, epharmacyState],
            location,
          })}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, isValid, submitCount, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  id={section?.sectionId}
                  className="mt-1 mb-3"
                  isSectionRequired={section.isSectionRequired}
                >
                  {section?.message && (
                    <p className="has-text-primary mb-2">{section?.message}</p>
                  )}{" "}
                  {section?.fields.map((field) => {
                    if (field?.name === "deliveryAddress") {
                      return (
                        <DeliveryAddress
                          setFieldValue={setFieldValue}
                          values={values}
                          addressFieldNames={addressFieldNames}
                          addresses={addressList}
                        />
                      )
                    } else if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values,
                            setFieldValue,
                            errors,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }

                    return null
                  })}
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                  <FormRequiredFields
                    errors={errors}
                    formFields={formFields}
                    state={epharmacyState}
                  />
                </Message>
              )}

              <ActionButtons
                back={{
                  label: <FontAwesomeIcon icon={faChevronLeft} />,
                  link: pageContext.backPath,
                }}
                submit={{
                  label: "Next: Payment Details",
                  loading: loading,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default DoctorEnrollmentForm
