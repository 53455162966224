const trimObjectWhiteSpaces = (object) => {
  Object.keys(object).forEach((prop) => {
    switch (typeof object[prop]) {
      case "string":
        object[prop] = object[prop].trim()
        break
      case "object":
        if (prop === undefined || prop === null || !!prop?.length) break
        object[prop] = trimObjectWhiteSpaces(prop)
        break
      default:
    }
  })

  return object
}

export default trimObjectWhiteSpaces
